import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/720p_Series_CGI_List/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "720p CGI Commands",
  "path": "/720p_Series_CGI_List/System_Menu/PTZ_Tour/",
  "dateChanged": "2018-03-26",
  "author": "Mike Polinowski",
  "excerpt": "Camera API control for developers",
  "image": "../../AU_SearchThumb_CGICommands_720p.png",
  "social": "/images/Search/AU_SearchThumb_CGICommands_720p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_CGIs_white.webp",
  "chapter": "720p CGI Commands"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='720p CGI Commands' dateChanged='2018-03-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Camera API control for developers' image='/images/Search/AU_SearchThumb_CGICommands_720p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_720p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/720p_Serie_CGI_Befehle/System_Menu/PTZ_Tour/' locationFR='/fr/720p_Series_CGI_List/System_Menu/PTZ_Tour/' crumbLabel="720p CGI Commands" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "the-system-menu--ptz-tour",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-system-menu--ptz-tour",
        "aria-label": "the system menu  ptz tour permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The System Menu :: PTZ Tour`}</h2>
    <br /><br />
    <hr></hr>
    <h3 {...{
      "id": "paramcgicmdsetptztour",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#paramcgicmdsetptztour",
        "aria-label": "paramcgicmdsetptztour permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=setptztour`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Function`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Set a Pan&Tilt Tour`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Authority`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`admin`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`var tour_enable`}</code>{` `}{`[0, 1]`}{` - If Active the `}<a parentName="td" {...{
              "href": "/en/720p_Series_CGI_List/System_Menu/PTZ/"
            }}>{`Alarmpreset Function`}</a>{` has to be Deactivated!`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`var tour_times`}</code>{`: `}{`[1-50]`}{` Tour Repetitions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`var tour_index`}</code>{`: Positions 0-7 divided by Semicolons`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`var tour_interval`}</code>{`: Resting Time on Position in Seconds `}{`[5-43200]`}{` divided by Semicolons`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remarks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If Active, the `}<a parentName="td" {...{
              "href": "/en/720p_Series_CGI_List/System_Menu/PTZ/"
            }}>{`Alarmpreset Function`}</a>{` has to be deactivated!`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Example`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=setptztour&-tour_enable=1&-tour_index=0;1;2;3;4;5;6;7&-tour_interval=5;5;5;5;5;5;5;5&-tour_times=50&cmd=setmdalarm&-aname=preset&-switch=off`}</code></td>
        </tr>
      </tbody>
    </table>
    <br /><br />
    <h3 {...{
      "id": "paramcgicmdptzctrl",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#paramcgicmdptzctrl",
        "aria-label": "paramcgicmdptzctrl permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=ptzctrl`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Function`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Start Pan&Tilt Tour`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Authority`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`admin`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`var step`}</code>{` `}{`[0, 1]`}{` - set to 0 for tour function!`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`var act`}</code>{` `}{`[tour, hscan, vscan, stop]`}{` - activate PTZ Tour, horizontal or vertical Scan or Stop Function`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remarks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If Active, the `}<a parentName="td" {...{
              "href": "/en/720p_Series_CGI_List/System_Menu/PTZ/"
            }}>{`Alarmpreset Function`}</a>{` has to be deactivated!`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Example`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=ptzctrl&-step=0&-act=tour`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET: `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`http://admin:instar@192.168.2.168/cgi-bin/hi3510/param.cgi?cmd=ptzctrl&-step=0&-act=stop`}</code></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      